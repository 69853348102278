import { initializeApp, FirebaseApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";

const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DATABASE_URL,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
};

// Initialize Firebase
const app: FirebaseApp = initializeApp(firebaseConfig);
const functions = getFunctions(app);

interface SubscribeResponse {
  data: {
    success: boolean;
    error?: string;
  };
}

document.getElementById("subscribe-form")?.addEventListener("submit", async (e: Event) => {
  e.preventDefault();

  const emailElement: HTMLInputElement | null = document.getElementById("email") as HTMLInputElement;
  const email = emailElement?.value;

  const subscribe = httpsCallable(functions, 'subscribe');
  const statusMessage = document.getElementById('status-message');
  const submitButton = document.querySelector('button[type="submit"]') as HTMLButtonElement;
  const buttonText = document.querySelector('.button-text') as HTMLElement;
  const loader = document.querySelector('.loader') as HTMLElement;
  const successIcon = document.querySelector('.success-icon') as HTMLElement;

  if(!statusMessage || !submitButton || !buttonText || !loader || !successIcon) {
    return;
  }

  // Hide the button text and show the loading icon
  buttonText.style.display = 'none';
  loader.style.display = 'inline-block';
  submitButton.disabled = true;

  try {
    const result = await subscribe({ email }) as SubscribeResponse;
    if(result.data.success) {
      console.log("Subscribed successfully");
      statusMessage.textContent = "Subscribed successfully!";
      statusMessage.style.color = 'green';

      // Hide the loading icon and show the success icon
      loader.style.display = 'none';
      successIcon.style.display = 'inline-block';
    }
    else if(result.data.error) {
      console.log(`Failed to subscribe: ${result.data.error}`);
      statusMessage.textContent = result.data.error;
      statusMessage.style.color = 'red';

      // Hide the loading icon, show the button text
      loader.style.display = 'none';
      buttonText.style.display = 'inline-block';
      submitButton.disabled = false;
    }
  } catch (error) {
    console.error(`Failed to subscribe: ${error}`);
    statusMessage.textContent = `An unexpected error occurred. Please try again later.`;
    statusMessage.style.color = 'red';

    // Hide the loading icon, show the button text
    loader.style.display = 'none';
    buttonText.style.display = 'inline-block';
    submitButton.disabled = false;
  }

  if(emailElement) {
    emailElement.value = "";
  }
});
